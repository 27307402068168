$arjo-blue-p: #015174;
$arjo-blue-90: #1a6281;
$white-p: #fff;
$pearl-s: #f7f4f3;
$sand-s: #d4ccc7;
$stone-s: #bfb4ae;
$rock-s: #aaa093;
$lava: #434342;
$wave-ac: #a6bcc9;
$flow-ac: #87657d;
$beam-ac: #f8c276;
$dark-grey-product-label: #989898;
$light-grey: #979797;
$light-grey-blur: transparentize(#979797, 0.5);
$black: #000;
$pebble-s: #e9e4e0;
$sway-ac: #dfbec2;
$beam: #f8c276;
$flow-10: #f3eff2;
$flow-25: #e1d8de;
$sway-25: #f7eff0;
$sway-50: #efdee0;
$wave-10: #f6f8f9;
$wave-25: #e9eef1;
$wave-50: #d2dde4;
$light-blue: #00638f;
$text-error: red;
$green: #91af91;
