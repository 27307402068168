@import '../../assets/styles/00-abstracts/abstracts';@import '../../assets/styles/01-mixins/mixins';.arjo-o-b2c-section {
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  background-color: $pearl-s;
  color: $lava;
  line-height: 1.5;

  p {
    margin: 0;
  }

  .container {
    max-width: var(--container-width);
    height: 100%;
    display: grid;
    background-color: $pearl-s;
    gap: 60px 0;

    @media (min-width: 1024px) {
      min-height: 100vh;
      grid-template-columns: 1fr 1fr;
    }
  }

  .main {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 1024px) {
      padding-bottom: 40px;
    }
  }

  .top-stripe {
    height: 60px;
    background-color: $white-p;
  }

  .main-content {
    margin: 0 auto;
    width: min(500px, 80vw);
    padding: 50px 0 40px 0;

    @media (min-width: 1024px) {
      margin: 0 min(6vw, 110px) 0 min(12vw, 216px);
      width: auto;
    }
  }

  .logo {
    color: $arjo-blue-p;
  }

  .title {
    margin-top: 50px;
    font-family: $font-santral;
    font-size: max(min(4vw, 60px), 35px);
    line-height: 1.167;
    color: $lava;
    letter-spacing: -1.5px;

    @media (min-width: 1024px) {
      margin-right: -30px;
    }
  }

  button:not([data-custom-style]) {
    position: relative;
    display: inline-block;
    font-family: $font-santral;
    font-weight: $bold;
    font-size: px2rem(14px);
    padding: px2rem(8px) px2rem(44px);
    border-radius: px2rem(18px);
    cursor: pointer;
    color: $white-p;
    border: none;
    background-color: $arjo-blue-p;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &::before {
      content: '';
      height: 100%;
      width: 0%;
      display: inline;
      position: absolute;
      background-color: $arjo-blue-90;
      top: 0;
      left: 50%;
      border-radius: px2rem(30px);
    }

    &:hover {
      transform: scale(1);

      &::before {
        z-index: -1;
        transition: 400ms;
        width: 100%;
        left: 0;
      }
    }
  }
}
